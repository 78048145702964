<template>
  <div class="flex h-screen bg-gray-50 dark:bg-gray-900">
    <NuxtLoadingIndicator />

    <div class="flex flex-col flex-1 w-full">
      <header class="sticky top-0 py-2 z-40 bg-white shadow-sm border-b-2 dark:bg-gray-800">
        <div class="flex items-center justify-between h-full px-3 mx-auto dark:text-purple-300">
          <div class="left flex items-center">
            <!-- hamburger -->

            <button
              :class="{ active: isMenuActive }"
              aria-label="Menu"
              class="m-0 hamburger--menu w-10 h-10 2xl:p-3 rounded-full hover:bg-gray-100 active:bg-gray-100"
              @click.prevent="handleMenu"
            >
              <TheHamburgerMenu></TheHamburgerMenu>
            </button>
            <button
              v-if="isMenuActive"
              aria-label="Menu"
              class="rounded-md focus:outline-none focus:shadow-outline-purple"
              @click="showDashboard"
            >
              <ElementsLogoClearLogo class="w-40"></ElementsLogoClearLogo>
            </button>
            <button
              v-if="!isMenuActive"
              :class="{ '-ml-10': isMenuActive }"
              aria-label="Menu"
              class="rounded-md focus:outline-none focus:shadow-outline-purple"
              @click="showDashboard"
            >
              <ElementsLogoClearLogo class="w-40"></ElementsLogoClearLogo>
            </button>
          </div>
          <div v-if="isVisibleForThisEnvironment" class="flex">
            <img alt="CXL Clear logo" class="w-48" src="@/assets/svg/context-labs-new-logo.svg" />
          </div>
          <div v-if="!online" class="text-center m-auto flex gap-2">
            <span class="text-red-500 text-lg 2xl:text-2xl"> {{ $t('offline') }}</span>
          </div>

          <div class="flex flex-row font-bold">
            <template v-if="!isDashboardOrRealm(router.currentRoute.value) && isSuperAdmin">
              <a
                v-if="realmId > 0 && selectedRealm"
                class="flex flex-row text-gray-500 hover:text-gray-700 pr-2"
                href="#"
                @click.prevent="openModel"
              >
                <div class="flex flex-row items-center justify-center gap-2">
                  <div
                    :class="{
                      'border rounded-full': realmId > 0 && selectedRealm
                    }"
                    class="w-10 h-10 2xl:w-14 2xl:h-14 flex items-center justify-center bg-gray-50"
                  >
                    <img
                      v-if="selectedRealm.brand"
                      :alt="selectedRealm.name"
                      :src="selectedRealm.brand"
                      class="w-5 h-5 2xl:w-6 2xl:h-6 min-w-[36px] max-w-[36px] max-h-[36px]"
                    />
                    <SvgSprite v-else class="w-5 h-5 2xl:w-6 2xl:h-6 fill-slate-300" symbol="placeholder-realm" />
                  </div>
                  <small v-if="selectedRealm" class="text-base 2xl:text-xl"
                    >{{ selectedRealm?.name }} [{{ selectedRealm?.id }}]</small
                  >
                </div>
              </a>
            </template>
            <template v-if="!isSuperAdmin">
              <div v-if="realmId > 0 && selectedRealm" class="flex flex-row text-gray-500 hover:text-gray-700 pr-2">
                <div class="flex flex-row items-center justify-center gap-2">
                  <div
                    :class="{
                      'border rounded-full': realmId > 0 && selectedRealm
                    }"
                    class="w-10 h-10 2xl:w-14 2xl:h-14 flex items-center justify-center bg-gray-50"
                  >
                    <img
                      v-if="selectedRealm.brand"
                      :alt="selectedRealm.name"
                      :src="selectedRealm.brand"
                      class="w-5 h-5 2xl:w-6 2xl:h-6 min-h-[36px] min-w-[36px] max-w-[36px] max-h-[36px] rounded-full"
                    />
                    <SvgSprite v-else class="w-5 h-5 2xl:w-6 2xl:h-6 fill-slate-300" symbol="placeholder-realm" />
                  </div>
                  <small v-if="selectedRealm" :id="`realm-id-${selectedRealm?.id}`" class="text-base 2xl:text-xl"
                    >{{ selectedRealm?.name }}
                  </small>
                </div>
              </div>
            </template>
          </div>
        </div>
      </header>
      <main class="h-full bg-slate-100 overflow-hidden">
        <div class="w-full flex h-full">
          <!-- sidebar -->
          <aside
            :class="isMenuActive ? 'w-60 2xl:w-[290px]' : 'w-14 relative z-50'"
            class="sticky top-0 flex-shrink-0 hidden bg-white dark:bg-gray-800 md:block"
          >
            <div
              :class="{
                disabled: !online
              }"
              class="sticky top-0 py-4 text-gray-500 dark:text-gray-400"
            >
              <TheSidebar></TheSidebar>
            </div>
          </aside>
          <div
            :class="{
              disabled: !online
            }"
            class="w-full flex flex-col justify-between pr-1"
          >
            <NuxtErrorBoundary>
              <template #error="{ error }">
                <ErrorBoundary :error="error" />
              </template>
              <HocRealmListModel>
                <ClientOnly>
                  <NuxtPage></NuxtPage>
                </ClientOnly>
              </HocRealmListModel>
            </NuxtErrorBoundary>
            <footer>
              <ThePageFooter></ThePageFooter>
            </footer>
          </div>
        </div>
        <!-- main container -->
      </main>
    </div>
    <Teleport to="body">
      <LoadersClearLoader v-model="fetchObj.isPending"></LoadersClearLoader>
    </Teleport>
    <InactivityLogout />
    <AssetsInventoryBasketSummary v-if="isOpened" v-bind="getModalProps" />
  </div>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { computed, ref, watch } from 'vue';
import { isDashboardOrRealm, onBeforeMount, useNuxtApp, useRouter, useRuntimeConfig } from '#imports';
import { useOnline } from '@vueuse/core';
import useClearFetchStore from '@/stores/useClearFetchStore';
import useGeneralStore from '@/stores/useGeneralStore';

import useTheme from '@/composables/useTheme';
import { storeToRefs } from 'pinia';
import useAuthStore from '@/stores/useAuthStore';
import { navigateTo } from '#app';
import useModal from '@/stores/useModal';
import { showInEnvironment } from '@/utils/constants';
import useRealmStore from '@/stores/useRealmStore';
import getLogOutChannel from '@/services/getLogOutChannel';

const online = useOnline();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const envMode = runtimeConfig.public.envMode;
const isVisibleForThisEnvironment = computed(() => showInEnvironment.includes(envMode));
const selectedRealm = ref(null);
const fetchObj = useClearFetchStore();
const genObj = useGeneralStore();
const authObj = useAuthStore();
const { isMenuActive, realmId } = storeToRefs(genObj);
const { isSuperAdmin } = storeToRefs(authObj);
const nuxtApp = useNuxtApp();
const realmObj = useRealmStore();
const realms = realmObj.getRealmData();
const modalObj = useModal();
const { isOpened, getModalProps } = storeToRefs(modalObj);

const handleRealmUpdate = (newRealmId) => {
  if (realms && newRealmId) {
    selectedRealm.value = realms.filter((item) => item.id === newRealmId)[0];
  }
};
const setSelectedRealm = () => {
  if (realmId.value) {
    handleRealmUpdate(realmId.value);
  }
};
watch(realmId, (newValue) => {
  handleRealmUpdate(newValue);
});
const openModel = () => {
  useNuxtApp().$event('openRealmModel', true);
};
if (process.client) {
  useNuxtApp().$listen('setRealmId', () => {
    setSelectedRealm();
  });
}
const handleMenu = () => {
  genObj.setMenu(!genObj.isMenuActive);
};

onBeforeMount(() => {
  setSelectedRealm();
  useTheme();
  if (!authObj?.getUser?.accept_term) {
    authObj.handleRedirect();
  }
});

// show dashboard
const showDashboard = () => {
  if (nuxtApp.$ability.can('list', 'Dashboard')) {
    navigateTo('/dashboard');
  } else {
    navigateTo('/projects');
  }
};

if (process.client) {
  useNuxtApp().$listen('HideBasketSummary', (evt) => {
    modalObj.setIsOpen(evt);
  });
}
const channel = getLogOutChannel();
channel.addEventListener('message', (e) => {
  if (e.data?.isLogOut) {
    window.location.assign('/');
  }
});
</script>
