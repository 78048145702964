<template>
  <div
    v-if="authObj.getIsAuthenticated"
    :class="{ 'h-full ease-in': generalObj.isMenuActive }"
    class="hidden md:block flex-none sticky max-h-screen"
  >
    <ul v-if="clearApp" class="border-t-0 border-r-0">
      <template v-for="menu in menuItems" :key="`${menu.symbol}-${menu.clickListner}`">
        <li
          v-if="menu.ability.extra() && clearApp.$ability.can(menu.ability.can.action, menu.ability.can.module)"
          :ref="menu.ref_name ? menu.ref_name : 'not_subMenu'"
          :class="`${menu.classes.join(' ')}${!generalObj.isMenuActive ? ' active' : ''} ${
            menu?.children?.length && !generalObj.isMenuActive ? 'submenu--show' : ''
          }`"
          class="relative mt-1"
          @mouseout="handleSubMenu(menu, false)"
          @mouseover="handleSubMenu(menu, true)"
          @click.prevent="() => (menu.clickListner.length ? () => {} : menu.clickHandler())"
        >
          <ClearLink
            v-if="!menu.children"
            v-tooltip.right="{
              content: !generalObj.isMenuActive ? menu.name : null,
              html: true
            }"
            :class="`${!reversedIcons.includes(menu.symbol) ? ' reversed' : ''}`"
            :element-id="menu.elementId"
            :to-link="menu.link"
            class="relative menu-items inline-flex items-center w-full 2xl:text-lg py-2 px-3 2xl:py-3 2xl:px-4 transition-colors duration-150"
          >
            <span class="inline-flex justify-center items-center w-7 h-7 2xl:w-8 2xl:h-8">
              <SvgSprite
                :class="`${reversedIcons.includes(menu.symbol) ? 'stroke-blue-500 fill-blue-500' : 'fill-gray-500'}`"
                :symbol="menu.symbol"
                class="icon text-gray-400 transition duration-75 group-hover:text-gray-900 w-full h-full"
              />
            </span>
            <span
              :class="{ hidden: !generalObj.isMenuActive }"
              class="ml-2 font-normal text-base 2xl:text-lg tracking-wide truncate"
              >{{ menu.name }}</span
            >
          </ClearLink>
          <ClearLink
            v-else
            :class="`${menu.subMenu_classes.join(' ')}${
              reversedIcons.includes(menu.symbol) || menu.children.length ? ' reversed' : ''
            }`"
            :element-id="menu.elementId"
            :to-link="menu.link"
            class="menu-items 2xl:text-lg py-2 px-3 2xl:py-3 2xl:px-4 relative flex flex-row items-center focus:outline-none text-gray-600"
            @click.prevent="() => menu.subMenu_click_handler(menu)"
          >
            <div class="flex w-full">
              <span class="inline-flex justify-center items-center w-7 h-7 2xl:w-8 2xl:h-8">
                <SvgSprite
                  :class="`${reversedIcons.includes(menu.symbol) ? 'stroke-blue-500 fill-blue-500' : 'fill-gray-500'}`"
                  :symbol="menu.symbol"
                  class="icon text-gray-400 transition duration-75 group-hover:text-gray-900 w-full h-full"
                />
              </span>
              <span
                :class="{ hidden: !generalObj.isMenuActive }"
                class="ml-2 font-normal text-base 2xl:text-lg tracking-wide truncate"
                >{{ menu.name }}
              </span>
            </div>
            <SvgSprite
              :class="`${isSubMenuOpen ? 'rotate-180 ease-in-out duration-300' : ''}`"
              class="icon w-3 h-3 ml-2 text-gray-400 fill-gray-500 transition duration-75 group-hover:text-gray-900"
              symbol="arrow-down-new"
            />
          </ClearLink>
          <div
            v-show="menu.children && menu.children.length && (menu.isMouseOver || isSubMenuOpen)"
            :class="{ '-left-3 py-11 absolute top-0 bottom-0 pl-[80px] m-auto': !generalObj.isMenuActive }"
          >
            <!-- top center arrow -->
            <div
              v-show="menu.isMouseOver"
              class="absolute -right-4 top-2 bottom-0 m-auto transform -translate-x-1/2 -translate-y-1/2 rotate-45 w-4 h-4 bg-white z-10"
            ></div>
            <!-- end top center arrow -->
            <ul
              :class="{ hidden: !isSubMenuOpen }"
              class="sub-menu flex flex-col relative items-center justify-start focus:outline-none pl-14 pt-1"
            >
              <template v-for="subMenu in menu.children" :key="`${subMenu.name.toLowerCase()}`">
                <li
                  v-if="clearApp.$ability.can(menu.ability.can.action, menu.ability.can.module)"
                  :class="subMenu.classes.join(' ')"
                  class="flex flex-row w-full sub-menu"
                >
                  <ClearLink
                    :element-id="subMenu.elementId"
                    :to-link="subMenu.link"
                    class="text-gray-600 flex items-center transition duration-75 py-1 2xl:py-[6px] px-3 2xl:px-4 w-full"
                  >
                    <span class="inline-flex justify-center items-center w-4 h-4 2xl:w-5 2xl:h-5">
                      <SvgSprite
                        :symbol="subMenu.symbol"
                        class="fill-gray-500 icon transition duration-75 w-full h-full"
                      />
                    </span>
                    <span class="ml-2 font-normal text-base 2xl:text-lg">{{ subMenu.name }}</span>
                  </ClearLink>
                </li>
              </template>
            </ul>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script setup>
import { computed, onBeforeMount, reactive, ref, watchEffect } from 'vue';
import { useI18n, useNuxtApp } from '#imports';
import { SvgSprite } from 'vue-svg-sprite';
import useAuthStore from '@/stores/useAuthStore';
import useGeneralStore from '@/stores/useGeneralStore';
import * as ModulePermission from '@/utils/modelNames';
import { onBeforeRouteUpdate, useRouter } from '#app';

const authObj = useAuthStore();
const reversedIcons = ref([
  // 'project-management--new',
  // 'posted',
  // 'user-management',
  // 'transfer-history--new',
  // 'retirement-history',
  // 'logout-new',
  // 'settings'
]);
const generalObj = useGeneralStore();
const clearApp = useNuxtApp();
const { t } = useI18n();
const isSubMenuOpen = ref(false);
const isVisibleToAdmin = () => authObj.getUser?.role_type > 0;
const isVisibleToOwner = () => authObj.getUser?.role_type === 1;
const handleSubMenuOpen = () => {
  generalObj.setMenu(true);
  isSubMenuOpen.value = !isSubMenuOpen.value;
};
const onLogout = async () => {
  await authObj.logout();
};
onBeforeRouteUpdate((route) => {
  const currentRoutePath = route.fullPath;
  isSubMenuOpen.value = false;
  if (currentRoutePath.includes('/all-posted-assets') || currentRoutePath.includes('/my-posts')) {
    isSubMenuOpen.value = true;
  }
});
onBeforeMount(() => {
  generalObj.setMenu(false);
});

const router = useRouter();
watchEffect(() => {
  if (!generalObj.isMenuActive) {
    isSubMenuOpen.value = false;
  }
  if (
    router.currentRoute.value.fullPath.includes('/all-posted-assets') ||
    router.currentRoute.value.fullPath.includes('/my-posts')
  ) {
    isSubMenuOpen.value = generalObj.isMenuActive;
  }
});
const subMenuParentClass = computed(() => {
  const cls = [];
  if (router.currentRoute.value.fullPath.includes('/all-posted-assets')) {
    cls.push('router-link-exact-active');
  }
  return cls;
});
const sideMenu = [
  {
    name: t('menu_items.dashboard'),
    clickListner: 'dashboard',
    symbol: 'dashboard--v4',
    link: '/dashboard',
    elementId: 'dashboard',
    classes: ['dashboard--v4'],
    ability: {
      can: { action: 'list', module: ModulePermission.Dashboard },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.realm_management.heading'),
    clickListner: 'realm',
    symbol: 'realm-management-v4',
    elementId: 'realm-management',
    link: '/realms',
    classes: [],
    ability: {
      can: { action: 'view', module: ModulePermission.Realm },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.roles_permissions.heading'),
    clickListner: 'role',
    symbol: 'roles-permissions-v4',
    elementId: 'role-management',
    link: '/roles',
    classes: [],
    ability: {
      can: { action: 'view', module: ModulePermission.Role },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.user_management.heading'),
    clickListner: 'user',
    symbol: 'user-management-v4',
    elementId: 'user-management',
    link: '/users',
    classes: [],
    ability: {
      can: { action: 'view', module: ModulePermission.User },
      extra: () => true,
      effective: true
    }
  },
  {
    name:
      authObj.getUser && authObj.getUser.role_type >= 1 && clearApp.$ability.can('verify', ModulePermission.Project)
        ? t('menu_items.project_management.heading_verify')
        : t('menu_items.project_management.heading'),
    clickListner: 'project',
    symbol: 'project-management-v4',
    elementId: 'project-management',
    link: '/projects',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.Project },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.ea_inventory.menu'),
    clickListner: 'environmental-attributes',
    symbol: 'asset-inventory-v4',
    link: '/assets',
    elementId: 'asset-management',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.Asset },
      extra: isVisibleToAdmin,
      effective: true
    }
  },
  {
    name: t('menu_items.posted.menu'),
    clickListner: 'posted',
    subMenu_click_handler: handleSubMenuOpen,
    ref_name: 'subMenu_holder',
    subMenu_classes: subMenuParentClass,
    symbol: 'posted-v4',
    elementId: 'my-posts',
    link: '/posts/my-posts',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.Post },
      extra: isVisibleToAdmin,
      effective: true
    },
    children: [
      {
        name: t('menu_items.posted.sub_menu.my_posts.name'),
        clickListner: '',
        elementId: 'my-posts',
        link: '/posts/my-posts',
        symbol: 'my-post-v4',
        classes: [],
        ability: {
          can: { action: 'list', module: ModulePermission.Post },
          effective: true
        }
      },
      {
        name: t('menu_items.posted.sub_menu.others_post.name'),
        clickListner: '',
        elementId: 'all-posted-assets',
        link: '/posts/all-posted-assets',
        symbol: 'all-posted-assets-v4',
        classes: [],
        ability: {
          can: { action: 'list', module: ModulePermission.OtherPost },
          effective: true
        }
      }
    ]
  },
  {
    name: t('menu_items.requests.menu'),
    clickListner: 'requests',
    symbol: 'requests-v4',
    elementId: 'request-management',
    link: '/requests',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.Request },
      extra: isVisibleToAdmin,
      effective: true
    }
  },

  {
    name: t('menu_items.pending-transfer.menu'),
    clickListner: 'pending-transfer',
    symbol: 'pending-transfers-v4',
    elementId: 'pending-transfer-management',
    link: '/pending-transfers',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.PendingTransfer },
      extra: isVisibleToAdmin,
      effective: true
    }
  },
  {
    name: t('menu_items.transfer-history.menu'),
    clickListner: 'transfer-history',
    symbol: 'transfer-history-v4',
    elementId: 'transfer-history-management',
    link: '/transfer-history',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.Transfer },
      extra: isVisibleToAdmin,
      effective: true
    }
  },
  {
    name: t('menu_items.retire.menu'),
    clickListner: 'retirement-history',
    symbol: 'retire-assets-v4',
    elementId: 'retirement-history-list',
    link: '/retirement-history',
    classes: [],
    ability: {
      can: { action: 'list', module: ModulePermission.RetiredAsset },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.settings.heading'),
    clickListner: 'profile',
    symbol: 'settings-v4',
    elementId: 'my-settings',
    link: '/settings',
    classes: ['settings--v4'],
    ability: {
      can: { action: 'view', module: ModulePermission.Profile },
      extra: () => (isVisibleToOwner() || authObj.isAdmin),
      effective: true
    }
  },
  {
    name: t('menu_items.settings.heading'),
    clickListner: 'profile',
    symbol: 'settings-v4',
    elementId: 'my-settings',
    link: '/settings',
    classes: ['settings--v4'],
    ability: {
      can: { action: 'view', module: ModulePermission.Profile },
      extra: () => authObj.isSuperAdmin,
      effective: true
    }
  },
  {
    name: t('menu_items.profile.heading'),
    clickListner: 'profile',
    symbol: 'profile-v4',
    elementId: 'my-profile',
    link: '/profile',
    classes: [],
    ability: {
      can: { action: 'view', module: ModulePermission.Profile },
      extra: () => true,
      effective: true
    }
  },
  {
    name: t('menu_items.logout'),
    clickListner: '',
    clickHandler: onLogout,
    symbol: 'logout-v4',
    elementId: 'logout',
    link: '/',
    classes: [],
    ability: {
      can: { action: 'logout', module: ModulePermission.User },
      extra: () => true,
      effective: true
    }
  }
];
const menuItems = reactive(sideMenu);
const handleSubMenu = (menu, flag) => {
  if (menu?.children?.length && !generalObj.isMenuActive) {
    menu.isMouseOver = flag;
    if (flag) {
      generalObj.setMenu(false);
    }
  }
};
</script>
